(function(LIVEREZ, window, $) {
  $(document).ready(function() {
    /****************** secondary search box desktop date pickers ******************/
    var nowTemp = new Date();
    var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

    $('.datepicker-range')
      .datepicker({
        beforeShowDay: function(date) {
          return date.valueOf() < now.valueOf() ? 'disabled' : '';
        },
        format: dateFormat,
        autoclose: true,
        orientation: 'auto',
      })
      .on('changeDate', function(e) {
        if (e.target.id == 'txtStartDate') {
          var startDate = new Date(e.date);
          var newDate = new Date(e.date);
          newDate.setDate(newDate.getDate() + defaultSearchDepartureDays);
          $('#txtEndDate').datepicker('setDate', newDate);
          $('#txtEndDate').focus();

          $('#compareStartDate').val(
            startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear()
          );
        }

        if (e.target.id == 'txtEndDate') {
          var newEndDate = new Date(e.date);
          $('#compareEndDate').val(
            newEndDate.getMonth() + 1 + '/' + newEndDate.getDate() + '/' + newEndDate.getFullYear()
          );
        }
      })
      .data('datepicker');

    /****************** end desktop date pickers ******************/

    /****************** calendar page date picker ******************/
    $('.lr-calendar-search-desktop-datepicker').datepicker({
      beforeShowDay: function(date) {
        return date.valueOf() < now.valueOf() ? 'disabled' : '';
      },
      format: dateFormat,
      autoclose: true,
      orientation: 'auto',
    });

    LIVEREZ.Publisher.subscribe('mobiscroll-loaded', function(message) {
      $('.lr-calendar-search-mobile-datepicker')
        .mobiscroll()
        .calendar({
          theme: 'bootstrap',
          display: 'modal',
          controls: ['calendar'],
          minDate: now,
          dateFormat: dateFormat,
        });

      $('.lr-calendar-search-mobile-datepicker').change(function() {
        $('.lr-calendar-search-desktop-datepicker').val(this.value);
      });
    });

    /****************** end calendar page date picker ******************/

    LIVEREZ.Publisher.subscribe('mobiscroll-loaded', function(message) {
      /****************** secondary search box mobile date pickers ******************/
      var mobileStartInst = $('#txtStartDate-mobile')
        .mobiscroll()
        .calendar({
          theme: 'bootstrap',
          display: 'modal',
          controls: ['calendar'],
          minDate: now,
          closeOnSelect: true,
          dateFormat: dateFormat,
        })
        .mobiscroll('getInst');

      var mobileEndInst = $('#txtEndDate-mobile')
        .mobiscroll()
        .calendar({
          theme: 'bootstrap',
          display: 'modal',
          controls: ['calendar'],
          minDate: now,
          closeOnSelect: true,
          dateFormat: dateFormat,
        })
        .mobiscroll('getInst');

      $('#txtStartDate-mobile').change(function() {
        $('#txtStartDate').val(this.value);
        $('#compareStartDate').val(this.value);

        var startDateStr = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(
          $('#txtStartDate-mobile').val(),
          dateFormatType
        );
        var startDate = new Date(startDateStr);
        var endDateStr = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(
          $('#txtEndDate-mobile').val(),
          dateFormatType
        );
        var endDate = new Date(endDateStr);

        //console.log('startDateStr', startDateStr);
        //console.log('endDateStr', endDateStr);
        //console.log('startDate', startDate);
        //console.log('endDate', endDate);

        var diff = startDate - endDate;
        var days = diff / 1000 / 60 / 60 / 24;

        //console.log('diff', diff);
        //console.log('days', days);

        var newEnd = new Date(startDate);
        newEnd.setDate(startDate.getDate() + defaultSearchDepartureDays);
        //console.log('newEnd', newEnd);
        var newEndStr = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(
          LIVEREZ.DynsiteFunctions.DateShort(newEnd),
          dateFormatType
        );
        //console.log('newEndStr', newEndStr);

        if (startDate > endDate) {
          mobileEndInst.setDate(newEnd);
          $('#txtEndDate-mobile').val(newEndStr);
          $('#txtEndDate').val(newEndStr);
          $('#compareEndDate').val(newEndStr);
        }
      });

      $('#txtEndDate-mobile').change(function() {
        $('#txtEndDate').val(this.value);
        $('#compareEndDate').val(this.value);
      });
    });

    // lazy load echo images
    echo.init({
      throttle: 50,
      offsetVertical: 750,
    });

    if ($('.index-features-carousel').length) {
      $('.index-features-carousel').on('slid.bs.carousel', function(e) {
        echo.init();
      });
    }

    if ($('.alternative-properties-carousel').length) {
      $('.alternative-properties-carousel').on('slid.bs.carousel', function(e) {
        echo.init();
      });
    }

    // only load mobiscroll if mobile to because its not neccesary on desktop
    if (LIVEREZ.DynsiteFunctions.isMobileDevice()) {
      $.getScript(CDN_BASE_URL + '/3/common/js/mobiscroll.custom-2.10.1.min.js', function() {
        $('head').append(
          $('<link rel="stylesheet" type="text/css" />').attr(
            'href',
            CDN_BASE_URL + '/3/common/css/mobiscroll.custom-2.10.1.min.css'
          )
        );
        LIVEREZ.Publisher.publish('mobiscroll-loaded', 'ready');
      });
    }

    $('.popover-icon').popover({
      placement: 'top',
      trigger: 'hover',
    });

    if ($('.lr-typeahead').length) {
      var myOptions = {
        timeoutLength: 600,
        dataSource: function(callback, query) {
          $.ajax({
            type: 'GET',
            url: '/inc/api/webservices.aspx?method=propertysearch',
            data: {
              AdminCustDataID: ADMIN_CUST_DATA_ID,
              DynSiteID: DYN_SITE_ID,
              q: encodeURIComponent(query),
            },
            dataType: 'json',
            success: function(data) {
              callback(null, data);
            },
            error: function(data, status, error) {
              console({
                data: data,
                status: status,
                error: error,
              });
              callback(null, []);
            },
          });
        },
        onNewData: function(data, element) {
          if (data.length) {
            var retHtml = '';
            for (var i = 0; i < data.length; i++) {
              retHtml +=
                '<div><a href="javascript: LIVEREZ.DynsiteFunctions.goPropertyByID(' +
                data[i].pageDataID +
                ')">' +
                data[i].pid +
                '</a></div>';
            }
            return retHtml;
          } else {
            return '<div><a href="vacation-rentals-homes.asp">No Results</a></div>';
          }
        },
      };

      $('.lr-typeahead').lrtypeahead(myOptions);
    }
  });
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
