(function(LIVEREZ, window, $, undefined) {
  var InstantQuote = {};

  InstantQuote.getQuote = function(instantQuoteObj) {
    var _this = this;

    var _checkValidDates = LIVEREZ.DynsiteFunctions.checkValidStartEndDates(
      instantQuoteObj.StartDate,
      instantQuoteObj.EndDate
    );
    if (_checkValidDates) {
      _this.hideLoading();
      _this.setError(_checkValidDates);
      _this.showDates();
      _this.showButton('contact');
      return;
    }

    // if we already have an ajax request, abort it and use the new one
    if (_this.xhr) {
      _this.killAjax();
    }

    this.xhr = $.ajax({
      type: 'get',
      url:
        LIVEREZ.DynsiteFunctions.getServerName() + '/inc/api/webservices.aspx?method=instantquote',
      data: {
        AdminCustDataID: ADMIN_CUST_DATA_ID,
        DynSiteID: DYN_SITE_ID,
        PageDataID: instantQuoteObj.PageDataID,
        ad: instantQuoteObj.StartDate,
        dd: instantQuoteObj.EndDate,
        adults: instantQuoteObj.Adults,
        children: instantQuoteObj.Children,
        checkAvailable: true,
        LiveNetID: instantQuoteObj.LiveNetID || 0,
      },
      dataType: 'json',
      success: function(data) {
        // publish the data to all subscribers
        LIVEREZ.Publisher.publish(LIVEREZ.events.instantQuoteFinished, data);
      },
      error: function(data, status, error) {
        if (error !== 'abort') {
          _this.hideLoading();
          _this.setError(
            'There was an issue with the quote. Please try new travel dates or try again later.'
          );
          _this.showDates();
          _this.showButton('contact');
        }
      },
    });
  };

  // subscribe to the instant quote finished event
  LIVEREZ.Publisher.subscribe(LIVEREZ.events.instantQuoteFinished, function(topic, data) {
    // reset
    $('.has-specials').addClass('hidden');
    $('.quote-fee-row').addClass('hidden');
    $('.discounted-rent').removeClass('on-sale');

    // property is available without errors
    if (!data.error && data.isAvailable === 1) {
      $('.property-instant-quote-nightly-rate-before-specials').text(
        cleanPrice(data.averageDailyRateBeforeSpecials)
      );
      $('.property-instant-quote-nightly-rate').text(cleanPrice(data.averageDailyRate));

      $('.property-instant-quote-num-nights').text(data.quantityDays);

      $('.property-instant-quote-fees').text(cleanPrice(data.fees));
      if (data.fees !== '0.00') {
        $('.quote-fee-row').removeClass('hidden');
      }

      $('.property-instant-quote-tax').text(cleanPrice(data.taxTotal));

      $('.property-instant-quote-rent').text(cleanPrice(data.rent));
      $('.property-instant-quote-rent-before-specials').text(cleanPrice(data.rentBeforeSpecials));

      $('.property-instant-quote-start-date').text(
        LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(
          $('#property-instant-quote-start-date').val(),
          dateFormatType
        )
      );
      $('.property-instant-quote-end-date').text(
        LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(
          $('#property-instant-quote-end-date').val(),
          dateFormatType
        )
      );

      $('.property-instant-quote-total-price').text(cleanPrice(data.total));

      $('.property-instant-quote-deposit-due').text(cleanPrice(data.deposit));

      if (data.hasSpecials) {
        $('.has-specials')
          .removeClass('hidden')
          .wrap('<s />');
        $('.discounted-rent').addClass('on-sale');
      }

      LIVEREZ.InstantQuote.showPricing();

      if (OnlineBookings) {
        LIVEREZ.InstantQuote.showButton('book');
      } else {
        LIVEREZ.InstantQuote.showButton('contact');
      }

      $('.property-instant-quote-change-dates').removeClass('hidden');
    }
    // error or not available
    else {
      LIVEREZ.InstantQuote.showButton('contact');
      LIVEREZ.InstantQuote.setError(data.message.split('.').join('. <br>'));
      LIVEREZ.InstantQuote.showDates();
    }

    LIVEREZ.InstantQuote.showHeading(data.isAvailable);
    LIVEREZ.InstantQuote.hideLoading();
  });

  InstantQuote.killAjax = function(strError) {
    if (this.xhr.readystate != 4) {
      this.xhr.abort();
      this.xhr = null;
    }
  };

  InstantQuote.setError = function(strError) {
    $('.property-instant-quote-message')
      .html(strError)
      .removeClass('hidden');
  };

  InstantQuote.removeError = function(strButtonType) {
    $('.property-instant-quote-message')
      .empty()
      .addClass('hidden');
  };

  InstantQuote.showButton = function(strButtonType) {
    $('.property-instant-quote-action-button').addClass('hidden');
    if (strButtonType !== '') {
      $('.property-instant-quote-button-' + strButtonType).removeClass('hidden');
    }
  };

  InstantQuote.submitForm = function(strFormType) {
    $('#property-quote-form-' + strFormType).submit();
  };

  InstantQuote.showLoading = function() {
    $('.property-instant-quote-loading').removeClass('hidden');
  };

  InstantQuote.hideLoading = function() {
    $('.property-instant-quote-loading').addClass('hidden');
  };

  InstantQuote.showDates = function() {
    $('.property-instant-quote-dates').removeClass('hidden');
  };

  InstantQuote.hideDates = function() {
    $('.property-instant-quote-dates').addClass('hidden');
  };

  InstantQuote.showPricing = function() {
    $('.property-instant-quote-available').removeClass('hidden');
  };

  InstantQuote.hidePricing = function() {
    $('.property-instant-quote-available').addClass('hidden');
    $('.property-instant-quote-available').addClass('hidden');
  };

  InstantQuote.changeDates = function() {
    $('.property-instant-quote-change-dates').addClass('hidden');
    this.killAjax();
    this.showHeading(0);
    this.removeError();
    this.hideLoading();
    this.hidePricing();
    this.showDates();
    this.showButton('');
  };

  InstantQuote.showHeading = function(isAvailable) {
    $('.property-instant-quote-heading').addClass('hidden');

    if (isAvailable === 1) {
      $('.property-instant-quote-heading-available').removeClass('hidden');
    } else {
      $('.property-instant-quote-heading-default').removeClass('hidden');
    }
  };

  LIVEREZ.InstantQuote = InstantQuote;

  function cleanPrice(decimalPrice) {
    var parts = decimalPrice.split('.');
    if (parts[1] === '00') {
      decimalPrice = parts[0];
    }
    return decimalPrice;
  }
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
