(function(LIVEREZ, window, $, undefined) {
  'use strict';

  var DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  var PropertyDateRanges = {};

  PropertyDateRanges.getByPageDataID = function(pageDataId, callback) {
    LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeStart);

    $.ajax({
      type: 'get',
      url: LIVEREZ.DynsiteFunctions.getServerName() + '/inc/api/webservices.aspx?method=dateranges',
      data: {
        AdminCustDataID: ADMIN_CUST_DATA_ID,
        DynSiteID: DYN_SITE_ID,
        PageDataID: pageDataId,
      },
      dataType: 'json',
      success: function(data) {
        // publish the data to all subscribers
        LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeComplete);
        LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeSuccess, data);
        callback(null, data);
      },
      error: function(data, status, error) {
        LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeComplete);

        if (error !== 'abort') {
          LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeError, error);
          callback(error);
        } else {
          var fakeData = { dateRanges: [] };
          LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeSuccess, fakeData);
          callback(null, fakeData);
        }
      },
    });
  };

  PropertyDateRanges.buildDateRangeTable = function(preferences, dateRanges) {
    var container = $('<div />').addClass('table-responsive');
    var table = $(' <table />').addClass('table table-striped responsive-rate-table');
    var tableBody = $('	<tbody />');

    table.append(tableBody);

    tableBody.append(_buildHeaderRow(preferences));

    dateRanges.forEach(function(dateRange, i) {
      table.append(_buildDateRangeRow(preferences, dateRange, i));
    });

    container.append(table);

    return container;
  };

  var _buildHeaderRow = function(preferences) {
    var row = $('<tr />');

    row.append(
      $('<td />')
        .addClass('property-rate-titles')
        .html('<strong>Season</strong>')
    );
    row.append(
      $('<td />')
        .addClass('property-rate-titles')
        .html('<strong>Start</strong>')
    );
    row.append(
      $('<td />')
        .addClass('property-rate-titles')
        .html('<strong>End</strong>')
    );

    if (preferences.nightlyPricing) {
      if (preferences.pricingPerDayOfWeek) {
        row.append(
          $('<td />')
            .addClass('property-rate-titles')
            .html('<strong>Sun</strong>')
        );
        row.append(
          $('<td />')
            .addClass('property-rate-titles')
            .html('<strong>Mon</strong>')
        );
        row.append(
          $('<td />')
            .addClass('property-rate-titles')
            .html('<strong>Tue</strong>')
        );
        row.append(
          $('<td />')
            .addClass('property-rate-titles')
            .html('<strong>Wed</strong>')
        );
        row.append(
          $('<td />')
            .addClass('property-rate-titles')
            .html('<strong>Thu</strong>')
        );
        row.append(
          $('<td />')
            .addClass('property-rate-titles')
            .html('<strong>Fri</strong>')
        );
        row.append(
          $('<td />')
            .addClass('property-rate-titles')
            .html('<strong>Sat</strong>')
        );
      } else {
        row.append(
          $('<td />')
            .addClass('property-rate-titles property-rate-title-night')
            .html('<strong>Nightly</strong>')
        );
      }
    }

    if (preferences.weeklyPricing) {
      row.append(
        $('<td />')
          .addClass('property-rate-titles property-rate-title-week')
          .html('<strong>Weekly</strong>')
      );
    }

    if (preferences.monthlyPricing) {
      row.append(
        $('<td />')
          .addClass('property-rate-titles property-rate-title-month')
          .html('<strong>Monthly</strong>')
      );
    }

    if (preferences.showMinStayOnProperty) {
      row.append(
        $('<td />')
          .addClass('property-rate-titles property-rate-title-min-night')
          .html('<strong>Min Nights</strong>')
      );
    }

    return row;
  };

  var _buildDateRangeRow = function(preferences, dateRange, index) {
    var row = $('<tr />').addClass('property-date-range-row');

    var isRowEven = _isEven(index);
    row.addClass(isRowEven ? 'property-rate-tr-wt' : 'property-rate-tr-gr');
    row.addClass(dateRange.available ? 'available' : 'unavailable');

    row.append($('<td />').html(dateRange.rangeTitle.trim()));
    row.append($('<td />').html(dateRange.strDateRangeL));
    row.append($('<td />').html(dateRange.strDateRangeU));

    var pricingColumns = 0;

    if (preferences.nightlyPricing) {
      if (preferences.pricingPerDayOfWeek) {
        if (dateRange.hasOwnProperty('available') && !dateRange.available) {
          pricingColumns += 7;
        } else if (dateRange.dateRangeType === 2) {
          // week to week
          row.append(
            $('<td />')
              .attr('colspan', 7)
              .addClass('text-center')
              .html(_formatPricing(preferences, dateRange.weekly) + ' Per Week')
          );
        } else {
          row.append($('<td />').html(_formatPricing(preferences, dateRange.sunPrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.monPrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.tuePrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.wedPrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.thuPrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.friPrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.satPrice)));
        }
      } else {
        if (dateRange.hasOwnProperty('available') && !dateRange.available) {
          pricingColumns += 1;
        } else if (dateRange.nightly && dateRange.dateRangeType !== 2) {
          // week to week
          var nightlyHTML = _formatPricing(preferences, dateRange.nightly);
          if (dateRange.startingAtNightly) {
            nightlyHTML =
              '<div><s>' +
              _formatPricing(preferences, dateRange.startingAtNightly) +
              '</s></div>' +
              nightlyHTML;
          }
          row.append(
            $('<td />')
              .addClass('property-rate-night')
              .html(nightlyHTML)
          );
        } else {
          row.append(
            $('<td />')
              .addClass('property-rate-week-none')
              .html('-')
          );
        }
      }
    }

    if (preferences.weeklyPricing) {
      if (dateRange.hasOwnProperty('available') && !dateRange.available) {
        pricingColumns += 1;
      } else if (dateRange.weekly) {
        var weeklyHTML = _formatPricing(preferences, dateRange.weekly);
        if (dateRange.startingAtWeekly) {
          weeklyHTML =
            '<div><s>' +
            _formatPricing(preferences, dateRange.startingAtWeekly) +
            '</s></div>' +
            weeklyHTML;
        }
        row.append(
          $('<td />')
            .addClass('property-rate-week')
            .html(weeklyHTML)
        );
      } else {
        row.append(
          $('<td />')
            .addClass('property-rate-week-none')
            .html('-')
        );
      }
    }

    if (preferences.monthlyPricing) {
      if (dateRange.hasOwnProperty('available') && !dateRange.available) {
        pricingColumns += 1;
      } else if (dateRange.monthly && dateRange.dateRangeType !== 2) {
        // week to week
        var monthlyHTML = _formatPricing(preferences, dateRange.monthly);
        if (dateRange.startingAtMonthly) {
          monthlyHTML =
            '<div><s>' +
            _formatPricing(preferences, dateRange.startingAtMonthly) +
            '</s></div>' +
            monthlyHTML;
        }
        row.append(
          $('<td />')
            .addClass('property-rate-month')
            .html(monthlyHTML)
        );
      } else {
        row.append(
          $('<td />')
            .addClass('property-rate-week-none')
            .html('-')
        );
      }
    }

    if (preferences.showMinStayOnProperty) {
      if (dateRange.hasOwnProperty('available') && !dateRange.available) {
        pricingColumns += 1;
      } else {
        var cell = $('<td />')
          .addClass('property-rate-min-night-days')
          .html(dateRange.minimumNightsStayWebBooking || dateRange.minimumNightsStay || '-');
        if (dateRange.dateRangeType === 2) {
          // week to week
          cell.append(
            ' (' +
              DAYS_OF_WEEK[dateRange.weekToWeekCheckinDay - 1] +
              ' - ' +
              DAYS_OF_WEEK[dateRange.weekToWeekCheckinDay - 1] +
              ')'
          );
        }
        row.append(cell);
      }
    }

    if (pricingColumns) {
      row.append(
        $('<td />')
          .attr('colspan', pricingColumns)
          .addClass('text-left')
          .html('<span class="property-date-range-unavailable-text">Reserved</span>')
      );
    }

    return row;
  };

  var _formatPricing = function(preferences, price) {
    var newPrice = parseFloat(price).toFixed(2);
    return (
      preferences.currencySymbol + newPrice.replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    );
  };

  var _isEven = function(n) {
    return n === 0 || !!(n && !(n % 2));
  };

  var _isOdd = function(n) {
    return _isEven(Number(n) + 1);
  };

  LIVEREZ.PropertyDateRanges = PropertyDateRanges;
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
