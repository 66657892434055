(function(NAMESPACE, window, $) {
  try {
    $(window.document).ready(function() {
      if (LR_ENV === 'PROD' && window.ga && window.ga.loaded) {
        const page = window.location.pathname;

        const $propertyBookItNowButton = $(
          'div.property-instant-quote-action > a.property-instant-quote-button-book'
        );
        const $propertySidebarContactManagerButton = $(
          '#content2Left > div.row.property-side-contact-manager > div > a'
        );
        const $propertySendToFriendLink = $('a.property-send-to-friend-link');
        const $propertyCommunityLearnMoreLink = $('a.property-community-learn-more-link');
        const $propertyChangeDatesLink = $(
          '#propertyInstantQuote > div.panel-footer > a.property-instant-quote-change-dates'
        );
        const $propertyReviewWidgetReviewsAnchor = $(
          '#property-rating-panel > div > div.lr-info-block-property-rating-property-panel > div.lr-info-block-property-rating-show-reviews > a'
        );
        const $propertyAllPhotosButton = $(
          '#Photos > div.row > div > ul > li > a[data-target="#propGalleryModal"]'
        );
        const $propertyFullScreen = $(
          '#photo-gallery > div.rsOverflow > div.rsFullscreenBtn > div'
        );
        const $propertyShowAllAmenitiesExpand = $('.standard-amenities-section--show-more > a');
        const $searchComparePropertiesButton = $('.search-compare-button');
        const $searchViewMoreCalendars = $('.search-results-calendar-suggestions--view-more');
        const $searchChangeDatesButton = $('.search-update-dates');

        if ($propertyBookItNowButton.length) {
          $propertyBookItNowButton.on('click', function() {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Book It Now Button');
          });
        }

        if ($propertySidebarContactManagerButton.length) {
          $propertySidebarContactManagerButton.on('click', function() {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Sidebar Contact Manager Button');
          });
        }

        if ($propertySendToFriendLink.length) {
          $propertySendToFriendLink.on('click', function() {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Send to Friend Link');
          });
        }

        if ($propertyCommunityLearnMoreLink.length) {
          $propertyCommunityLearnMoreLink.on('click', function() {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Community Learn More Link');
          });
        }

        if ($propertyChangeDatesLink.length) {
          $propertyChangeDatesLink.on('click', function() {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Change Dates Link');
          });
        }

        if ($propertyReviewWidgetReviewsAnchor.length) {
          $propertyReviewWidgetReviewsAnchor.on('click', function() {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Reviews Widget Review Anchor');
          });
        }

        if ($propertyAllPhotosButton.length) {
          $propertyAllPhotosButton.on('click', function() {
            ga('lrTracker.send', 'event', 'Property', 'click', 'All Photos Button');
          });
        }

        if ($propertyFullScreen.length) {
          $propertyFullScreen.on('click', function() {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Slideshow Fullscreen Button');
          });
        }

        if ($searchComparePropertiesButton.length) {
          $searchComparePropertiesButton.on('click', function() {
            ga('lrTracker.send', 'event', 'Search', 'click', 'Compare Properties Button');
          });
        }

        if ($searchViewMoreCalendars.length) {
          $searchViewMoreCalendars.on('click', function() {
            ga('lrTracker.send', 'event', 'Search', 'click', 'View More Property Calendars button');
          });
        }

        if ($propertyShowAllAmenitiesExpand.length) {
          $propertyShowAllAmenitiesExpand.on('click', function() {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Show All Amenities Expand Link');
          });
        }

        // search page
        if (page === 'vacation-rentals-homes-search.asp') {
          $searchComparePropertiesButton.on('click', function() {
            ga('lrTracker.send', 'event', 'Search', 'click', 'Compare Properties Button');
          });

          $searchViewMoreCalendars.on('click', function() {
            ga('lrTracker.send', 'event', 'Search', 'click', 'View More Property Calendars button');
          });

          $searchChangeDatesButton.on('click', function() {
            ga('lrTracker.send', 'event', 'Search', 'click', 'Change Dates button');
          });

          $(document).on('click', '.search-pagination a', function(e) {
            ga('lrTracker.send', 'event', 'Search', 'click', 'Paging Anchor', $(this).data('page'));
          });
        }
      }
    });
  } catch (error) {
    console.error(error);
  }
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
