/*
 * @author Joshua Hurst & Frank Linehan
 * @date 6/16/2015
 *
 * @description A Pub/Sub Pattern Module for Handling JS Events
 */

(function(LIVEREZ, window, $, undefined) {
  var pubsub = {};
  var topics = {};

  // An topic identifier
  var subUid = -1;

  pubsub.publish = function(topic, args) {
    if (!topics[topic]) {
      return false;
    }

    var subscribers = topics[topic],
      len = subscribers ? subscribers.length : 0;

    while (len--) {
      subscribers[len].func(topic, args);
    }

    return this;
  };

  pubsub.subscribe = function(topic, func) {
    if (!topics[topic]) {
      topics[topic] = [];
    }

    var token = (++subUid).toString();
    topics[topic].push({
      token: token,
      func: func,
    });
    return token;
  };

  pubsub.unsubscribe = function(token) {
    for (var m in topics) {
      if (topics[m]) {
        for (var i = 0, j = topics[m].length; i < j; i++) {
          if (topics[m][i].token === token) {
            topics[m].splice(i, 1);
            return token;
          }
        }
      }
    }
    return this;
  };

  LIVEREZ.Publisher = pubsub;
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
