(function(LIVEREZ, window, $, undefined) {
  var CALENDAR_DAY_TYPES = {
    Available: 0,
    Unavailable: 1,
    CheckIn: 2,
    CheckOut: 3,
    EarlyCheckIn: 4,
    LateCheckOut: 5,
    EarlyCheckInLateCheckOut: 6,
    SingleNightRental: 7,
    TurnOver: 8,
    TurnOverEarlyCheckIn: 9,
    TurnOverLateCheckOut: 10,
  };

  var CalendarAvailability = function(dynsiteId, adminCustDataID, pageDataId) {
    this.dynsiteId = dynsiteId;
    this.adminCustDataId = adminCustDataID;
    this.pageDataId = pageDataId;

    this.unavailableTypes = [
      CALENDAR_DAY_TYPES.Unavailable,
      CALENDAR_DAY_TYPES.EarlyCheckIn,
      CALENDAR_DAY_TYPES.LateCheckOut,
      CALENDAR_DAY_TYPES.EarlyCheckInLateCheckOut,
      CALENDAR_DAY_TYPES.SingleNightRental,
      CALENDAR_DAY_TYPES.TurnOver,
      CALENDAR_DAY_TYPES.TurnOverEarlyCheckIn,
      CALENDAR_DAY_TYPES.TurnOverLateCheckOut,
    ];

    this.unavailableDateObjs = [];
    this.unavailableDateObjsMobile = [];
    this.queriedYears = [];
  };

  CalendarAvailability.prototype.getCalendarData = function(startDate, endDate, callback) {
    var _this = this;

    if (_this.xhr && _this.xhr.readystate != 4) {
      _this.xhr.abort();
      _this.xhr = null;
    }

    this.queriedYears.push(new Date(startDate).getFullYear());

    _this.xhr = $.ajax({
      type: 'get',
      url:
        LIVEREZ.DynsiteFunctions.getServerName() +
        '/inc/api/webservices.aspx?method=propertycalendardata',
      data: {
        AdminCustDataID: _this.adminCustDataId,
        DynSiteID: _this.dynsiteId,
        PageDataID: _this.pageDataId,
        ad: startDate,
        dd: endDate,
      },
      dataType: 'json',
      success: function(data) {
        try {
          // var offsetHours = ((+(new Date().getTimezoneOffset()) / 60 ) * - 1);

          var addHours = function(d, h) {
            d.setTime(d.getTime() + h * 60 * 60 * 1000);
            return d;
          };

          data.forEach(function(dateObj) {
            var _type = +dateObj.calendarDayTypes;

            if (
              _this.unavailableTypes.indexOf(_type) > -1 ||
              (_type === CALENDAR_DAY_TYPES.CheckIn && +dateObj.earlyCheckIn === 1) ||
              (_type === CALENDAR_DAY_TYPES.CheckOut && +dateObj.lateCheckOut === 1)
            ) {
              // dont add early check in or late check out to disavled days if settings allow for turnovers on these
              if (
                (_type === CALENDAR_DAY_TYPES.EarlyCheckIn && searchAllowForEarlyCheckIn === 1) ||
                (_type === CALENDAR_DAY_TYPES.LateCheckOut && searchAllowForLateCheckOut === 1)
              ) {
                return;
              }

              // var uDate = new Date(new Date(dateObj.startDate.split('T')[0].replace(/-/g, '\/')).setHours(0,0,0,0));
              // var uDate = new Date(dateObj.startDate.split('T')[0].replace(/-/g, '\/'));
              // var uDateMobile = new Date(dateObj.startDate.split('T')[0].replace(/-/g, '\/'));
              // var uDateOffset = offsetHours;

              // // console.log('=============== ' + dateObj.startDateString + ' ================');
              // // console.log('uDate before', uDate);
              // // console.log('offset hours', offsetHours);
              // // console.log('uDateOffset hours', uDateOffset);
              // // console.log('isDST', isDST(uDate));

              // if(isDST(uDate)){
              // 	uDateOffset = uDateOffset + 1
              // }

              // // console.log('uDateOffset hours', uDateOffset);

              // uDate = addHours(uDate, uDateOffset);

              // // console.log('uDate after', uDate);

              // _this.unavailableDateObjs.push(uDate);
              // _this.unavailableDateObjsMobile.push(uDateMobile);

              // var formatted8601 = LIVEREZ.DynsiteFunctions.dateStringToISO8601(dateObj.startDateString);
              var formattedDateObj = LIVEREZ.DynsiteFunctions.dateStringToObject(
                dateObj.startDateString
              );

              if (dateFormatType === 1) {
                dateObj.startDateString = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(
                  dateObj.startDateString,
                  dateFormatType
                );
              }

              _this.unavailableDateObjs.push(dateObj.startDateString);
              _this.unavailableDateObjsMobile.push(formattedDateObj);
            }
          });

          _this.unavailableDateObjs = _this.unavailableDateObjs.filter(function(
            element,
            index,
            array
          ) {
            return array.indexOf(element) >= index;
          });

          _this.unavailableDateObjsMobile = _this.unavailableDateObjsMobile.filter(function(
            element,
            index,
            array
          ) {
            return array.indexOf(element) >= index;
          });

          callback();
        } catch (ex) {
          console.error(ex);
          callback();
        }
      },
      error: function(data, status, error) {
        $('.datepicker-overlay').remove();

        console.log(data);
        console.log(status);
        console.log(error);
      },
    });
  };

  // function isDST(t) { //t is the date object to check, returns true if daylight saving time is in effect.
  // 	var jan = new Date(t.getFullYear(),0,1);
  // 	var jul = new Date(t.getFullYear(),6,1);
  // 	return Math.min(jan.getTimezoneOffset(),jul.getTimezoneOffset()) == t.getTimezoneOffset();
  // }

  CalendarAvailability.prototype.findDateConflicts = function(start, end) {
    var error = false;

    var start = new Date(LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(start, dateFormatType));
    var end = new Date(LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(end, dateFormatType));

    for (var i = 0; i < this.unavailableDateObjs.length; i++) {
      var tmpDate = this.unavailableDateObjs[i];
      if (tmpDate >= start && tmpDate <= end) {
        error = true;
      }
    }

    return error;
  };

  LIVEREZ.CalendarAvailability = CalendarAvailability;
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
