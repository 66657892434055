/* =========================================================
 * lr.typeahead.js
 * Repo: http://stash.liverez.com:7990/scm/bow/lr.typeahead.git
 * =========================================================
 * Authored by LIVEREZ.COM
 * Contributors: Joshua Hurst, Frank Linehan, Jeremy Lotz
 * ========================================================= */

(function($, undefined) {
  'use strict';

  var _timeout;

  var DEFAULTS = {
    timeoutLength: 500,
    dataSource: function(callback, query) {
      var _sendData = {
        q: encodeURIComponent(query),
      };

      $.ajax({
        type: 'GET',
        url: 'data/properties.json',
        data: _sendData,
        dataType: 'json',
        success: function(data) {
          callback(null, data);
        },
        error: function(data, status, error) {
          callback({
            data: data,
            status: status,
            error: error,
          });
        },
      });
    },
    onNewData: function(data) {
      console.log(data);
    },
  };

  // the actual plugin
  var Plugin = function(option) {
    return this.each(function() {
      var $this = $(this);
      var options = typeof option == 'object' && option;

      options = $.extend(DEFAULTS, options);

      new Typeahead($this, options);
    });
  };

  // the constructor
  var Typeahead = function(element, options) {
    var _this = this;

    this.element = element;
    this.options = options;
    this.init(this.element, this.options);
  };

  Typeahead.prototype.init = function(element, options) {
    var _this = this;

    var wrapperDiv = $('<div/>')
      .css({
        position: 'relative',
        width: this.element.width() + 2,
      })
      .addClass('lr-typeahead-wrapper');

    var typeaheadDiv = $('<div/>');

    typeaheadDiv
      .css({
        position: 'absolute',
        top: this.element.outerHeight(),
        left: '0',
        width: '100%',
        display: 'none',
        border: '1px solid #ccc',
        'border-top': 'none',
        background: 'white',
        color: '#444',
        'max-height': '50vh',
        'overflow-y': 'scroll',
      })
      .html('Loading Data...')
      .addClass('lr-typeahead');

    this.element.wrap(wrapperDiv);
    this.element.after(typeaheadDiv);

    element.on('keydown', function() {
      _this.open('keydown', typeaheadDiv, _this.element);
    });

    element.on('keyup', function() {
      if (_this.element.val()) {
        if (_timeout) {
          clearTimeout(_timeout);
        }

        _timeout = setTimeout(function() {
          _this.getData('keyup', function(error, data) {
            if (error) {
              typeaheadDiv.html('No Results');
            } else {
              _this.insertTypeAheadData(typeaheadDiv, data);
            }
          });
        }, _this.options.timeoutLength);
      } else {
        _this.close('keyup', typeaheadDiv);
      }
    });
  };

  Typeahead.prototype.open = function(type, typeaheadelem, element) {
    var domTypeAheadElem = typeaheadelem.get(0);

    typeaheadelem.html('Loading Data...');

    if (domTypeAheadElem.style.display === 'none') {
      $('body').on('click', function(e) {
        if (!typeaheadelem.is(e.target) && typeaheadelem.has(e.target).length === 0) {
          domTypeAheadElem.style.display = 'none';
          element.val('');
        }
        $('body').off('click');
      });

      domTypeAheadElem.style.display = 'block';
    }
  };

  Typeahead.prototype.close = function(type, typeaheadelem) {
    var _this = this;

    var domTypeAheadElem = typeaheadelem.get(0);

    if (domTypeAheadElem.style.display === 'block') {
      domTypeAheadElem.style.display = 'none';
    }

    return this;
  };

  Typeahead.prototype.insertTypeAheadData = function(typeaheadelem, data) {
    var _this = this;

    typeaheadelem.empty();
    typeaheadelem.append(_this.options.onNewData(data));

    return this;
  };

  Typeahead.prototype.getData = function(type, callback) {
    var _this = this;
    _this.options.dataSource(callback, _this.element.val());

    return this;
  };

  var old = $.fn.lrtypeahead;

  $.fn.lrtypeahead = Plugin;
  $.fn.lrtypeahead.Constructor = Typeahead;
})(lrjQ);
