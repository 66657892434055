(function(LIVEREZ, window, $) {
  LIVEREZ.events = {
    searchCreateMapImage: 'search-map-image',
    searchMapOpen: 'search-map-open',
    searchMapClose: 'search-map-close',
    searchStart: 'search-start',
    searchComplete: 'search-complete',
    resultsRenderComplete: 'results-render-complete',
    instantQuoteFinished: 'instant-quote-finished',
    calDataFinished: 'cal-data-finished',
    searchSuggestionsStart: 'search-suggesttions-start',
    searchSuggestionsComplete: 'search-suggesttions-complete',
    calendarSearchStart: 'calendar-search-start',
    calendarSearchComplete: 'calendar-search-complete',
    calendarSearchRenderComplete: 'calendar-search-render-complete',
    propertyDateRangeStart: 'property-date-range-start',
    propertyDateRangeComplete: 'property-date-range-complete',
    propertyDateRangeError: 'property-date-range-error',
    propertyDateRangeSuccess: 'property-date-range-success',
  };
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
